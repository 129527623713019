import React, { useEffect, ReactElement } from 'react';
import { IGlobalThemeWrapperProps } from './types';
// import GlobalFonts from 'styles/fonts';
import { SCBaseThemeWrapper } from 'styles/global-styles';
import { ThemeProvider } from 'styled-components';
import { CssBaseline } from '@mui/material/';
import { ThemeProvider as MUIThemeProvider, createTheme } from '@mui/material/styles';
import { useOktaAuth } from '@okta/okta-react';
import { AuthenticationSessionContext } from 'context/authenticationSessionContext';
import { IAuthenticationDataState } from 'types/authenticatation-session';
import { JwtAccessTokenPayload } from 'types/JwtAccessTokenPayload';
import { AuthenticationGroupRolesEnum } from 'enums/AuthenticationEnums';
import { Routes } from 'routes';
import { AlertContext, IAlertTypeState } from 'context/Alert';

const GlobalThemeWrapper = ({ children }: IGlobalThemeWrapperProps): ReactElement => {
    const { oktaAuth, authState } = useOktaAuth();
    const { authenticationData, updateAuthenticationType } = React.useContext(
        AuthenticationSessionContext
    ) as IAuthenticationDataState;
    const { contextStateAlert } = React.useContext(AlertContext) as IAlertTypeState;
    useEffect(() => {
        if (authState?.isAuthenticated) {
            if (authState?.accessToken) {
                const accessToken = authState.accessToken;
                const decoded = accessToken.claims as JwtAccessTokenPayload;

                const distributorKey = getCompanyKeyFromRoleGroup(decoded?.groups, CompanyRoleGroupPrefix.Distributor);
                const loanServicerKey = getCompanyKeyFromRoleGroup(
                    decoded?.groups,
                    CompanyRoleGroupPrefix.LoanServicer
                );
                updateAuthenticationType({
                    ...authenticationData,
                    isAuthenticated: true,
                    userName: decoded.sub ? decoded.sub : undefined,
                    distributorKey: distributorKey,
                    loanServicerKey: loanServicerKey,
                    signOut: () => oktaAuth.signOut(),
                    groups: decoded.groups,
                    authenticatedRedirectPath: decoded.groups?.includes(AuthenticationGroupRolesEnum.ADMIN_ACCESS_WRITE)
                        ? Routes.CLAIMS_MANAGEMENT
                        : Routes.ADMIN_LANDING
                });
            }
        }
    }, [authState, oktaAuth]);
    const fontFamilies = "'Sul Sans', 'Century Gothic', 'CenturyGothic', sans-serif";
    const tsTheme = {
        tsTheme: {
            typography: {
                fontFamily: fontFamilies
            },
            colors: {
                primary: {
                    black: 'rgba(0, 0, 0, 1)',
                    lightGrey: 'rgba(242, 242, 242, 1)',
                    white: 'rgba(255, 255, 255, 1)',
                    yellow: 'rgba(250, 224, 126, 1)'
                },
                secondary: {
                    digitalBlack: 'rgba(35, 31, 32, 1)',
                    darkGray: 'rgba(100, 100, 104, 1)',
                    deepBlue: 'rgba(25, 48, 98, 1)',
                    deepBlue20: 'rgba(209, 214, 224, 1)',
                    brightBlue: 'rgba(96, 140, 255, 1)',
                    brightGreen: 'rgba(102, 204, 153, 1)'
                },
                colorTints: {
                    warningRedText: 'rgba(208, 2, 27, 1)',
                    brightBlue20: 'rgba(223, 232, 255, 1)',
                    brightBlue20Selected: 'rgba(223, 232, 255, 0.2)'
                },
                functional: {
                    deepBlue60: 'rgba(25, 48, 98, 1)',
                    successGreenBackground: 'rgba(237, 247, 237, 1)',
                    warningRedBackground: 'rgba(252, 221, 222, 1)'
                },
                notification: {
                    systemBlue01: 'rgba(85, 151, 251, 1)'
                },
                greyScale: {
                    white: 'rgba(244, 248, 250, 1)',
                    contrastGrey03: 'rgba(230, 234, 235, 1)',
                    contrastBlack: 'rgba(38, 44, 48, 1)'
                }
            }
        },
        AlertState: contextStateAlert
    };
    const theme = createTheme({
        typography: {
            fontFamily: fontFamilies
        },
        components: {
            MuiTypography: {
                defaultProps: {
                    fontFamily: fontFamilies
                }
            }
        },
        palette: {
            action: {
                hover: tsTheme.tsTheme.colors.greyScale.contrastGrey03,
                active: tsTheme.tsTheme.colors.colorTints.brightBlue20,
                selected: tsTheme.tsTheme.colors.colorTints.brightBlue20
            },
            common: {
                black: tsTheme.tsTheme.colors.greyScale.contrastBlack,
                white: tsTheme.tsTheme.colors.greyScale.white
            }
        }
    });

    /***
     * Given a list of authenticated Okta user "groups", and a type of company to search for, return a Company Key ("happymoney", "qcash", etc.)
     * This return value is just the suffix on the end of the relevant Okta role group. This function does not check against Launch Darkly or our database.
     */
    const getCompanyKeyFromRoleGroup = (
        oktaGroups: string[] | undefined,
        roleGroupPrefix: CompanyRoleGroupPrefix
    ): string | undefined => {
        if (typeof oktaGroups == 'undefined') return undefined;
        const companyMatch =
            oktaGroups?.includes(AuthenticationGroupRolesEnum.ADMIN_EXTERNAL) &&
            oktaGroups?.findIndex((element) => element.includes(roleGroupPrefix)) !== -1
                ? oktaGroups && oktaGroups[oktaGroups?.findIndex((element) => element.includes(roleGroupPrefix))]
                : undefined;
        const indexOfCompanyKey = companyMatch?.lastIndexOf('_');
        const companyKeySubstring = indexOfCompanyKey && companyMatch?.substring(indexOfCompanyKey + 1);
        return companyKeySubstring as string;
    };

    enum CompanyRoleGroupPrefix {
        Distributor = 'r-paymentguard_distributor_',
        LoanServicer = 'r-paymentguard_loanservicer_'
    }
    // const GlobalFont = GlobalFonts.displayName;
    console.info(
        '\n::::::::::::::::::::::::::::::::GlobalThemeWrapper:::::::::::::::::::::::::::::::::',
        '\n::oktaAuth::',
        oktaAuth,
        '\n::authState::',
        authState,
        '\n::tsTheme::',
        tsTheme,
        '\n::theme::',
        theme,
        // '\n::GlobalFonts::',
        // GlobalFonts,
        // '\n::GlobalFont::',
        // GlobalFont,
        '\n:::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::::'
    );

    return (
        // <GlobalFonts>
        <ThemeProvider theme={tsTheme}>
            <MUIThemeProvider theme={theme}>
                <SCBaseThemeWrapper>
                    <CssBaseline />
                    {children}
                </SCBaseThemeWrapper>
            </MUIThemeProvider>
        </ThemeProvider>
        // </GlobalFonts>
    );
};
export { GlobalThemeWrapper };
